var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getAppName } from '@/config/helperFunctions';
import { FcaLogo, FcaLogoDisabled, } from '@frontend/design-system/theme/icons/logos/FCA';
import { MyOpportunitiesLogo, MyOpportunitiesLogoDisabled, } from '@frontend/design-system/theme/icons/logos/MyOpportunities';
import { PlannerLogo, PlannerLogoDisabled, } from '@frontend/design-system/theme/icons/logos/Planner';
import { ReportsLogo, ReportsLogoDisabled, } from '@frontend/design-system/theme/icons/logos/Reports';
import { IAppNames } from '@frontend/domain/models/App';
import React from 'react';
export const AppAccessIcon = (_a) => {
    var { app, isActive, fullLogo = false, onClick, customStyles } = _a, props = __rest(_a, ["app", "isActive", "fullLogo", "onClick", "customStyles"]);
    switch (app) {
        case IAppNames.REPORTS: {
            return isActive ? (_jsx(ReportsLogo, Object.assign({}, props, { label: getAppName(app), "aria-label": 'Reports logo', onClick: onClick, fullLogo: fullLogo, style: Object.assign({}, customStyles) }))) : (_jsx(ReportsLogoDisabled, Object.assign({}, props, { label: getAppName(app), "aria-label": 'Reports no access logo', onClick: onClick, fullLogo: fullLogo, style: Object.assign({}, customStyles) })));
        }
        case IAppNames.FCA: {
            return isActive ? (_jsx(FcaLogo, Object.assign({}, props, { label: getAppName(app), "aria-label": 'FCA logo', onClick: onClick, fullLogo: fullLogo, style: Object.assign({}, customStyles) }))) : (_jsx(FcaLogoDisabled, Object.assign({}, props, { label: getAppName(app), "aria-label": 'FCA no access logo', onClick: onClick, fullLogo: fullLogo, style: Object.assign({}, customStyles) })));
        }
        case IAppNames.PLANNER: {
            return isActive ? (_jsx(PlannerLogo, Object.assign({}, props, { label: getAppName(app), "aria-label": 'Planner logo', onClick: onClick, fullLogo: fullLogo, style: Object.assign({}, customStyles) }))) : (_jsx(PlannerLogoDisabled, Object.assign({}, props, { label: getAppName(app), "aria-label": 'Planner no access logo', onClick: onClick, fullLogo: fullLogo, style: Object.assign({}, customStyles) })));
        }
        case IAppNames.PIQ: {
            return isActive ? (_jsx(MyOpportunitiesLogo, Object.assign({}, props, { label: getAppName(app), "aria-label": 'PIQ logo', onClick: onClick, fullLogo: fullLogo, style: Object.assign({}, customStyles) }))) : (_jsx(MyOpportunitiesLogoDisabled, Object.assign({}, props, { label: getAppName(app), "aria-label": 'PIQ no access logo', onClick: onClick, fullLogo: fullLogo, style: Object.assign({}, customStyles) })));
        }
    }
};
