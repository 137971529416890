import { createPostHandler } from '../testing/msw/handlerUtils';
import { PlanCompareResponseBuilder } from '../testing/planMocks';
const scenarioEndpoints = {
    compare: '/api/v1/Plan/compare',
};
const scenarioCompareHandler = createPostHandler(scenarioEndpoints.compare, new PlanCompareResponseBuilder({}).build());
const scenarioHandlers = [scenarioCompareHandler];
export const scenarioHandler = {
    endpoints: scenarioEndpoints,
    handlers: scenarioHandlers,
};
