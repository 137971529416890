var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { convertBlobToFile } from '../conversion/GeneralConversions';
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertToEVRequest, convertToIFacilityMeasureSummary, convertToIFacilityPriority, convertToLightingRequest, convertToMeasureExportRequest, convertToMeasureImportRequest, convertToSolarRequest, } from '../models/Measure/MeasureConversions';
export const useMeasureService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchPriority = (organizationId, facilityId) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.measure.getApiV1MeasureLoad(organizationId, facilityId), {
            error: { label: 'Error occurred retrieving Priority' },
            success: { enabled: false },
        }).then((response) => response.map(convertToIFacilityPriority));
    });
    const fetchSummary = (organizationId) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.measure.getApiV1MeasureList(organizationId), {
            error: { label: 'Error occurred retrieving Facilities' },
            success: { enabled: false },
        }).then((response) => response.map(convertToIFacilityMeasureSummary));
    });
    const updateLightingPriority = (updateLighting) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.measure.postApiV1MeasureRefineLighting(convertToLightingRequest(updateLighting)), {
            error: {
                label: 'Error occurred updating Lighting Priority',
            },
            success: {
                enabled: true,
                label: 'Lighting Priority Updated',
            },
        }).then((_res) => _res.priorityScore);
    });
    const updateEvPriority = (updateEv) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.measure.postApiV1MeasureRefineEv(convertToEVRequest(updateEv)), {
            error: { label: 'Error occurred updating EV Priority' },
            success: { enabled: true, label: 'EV Priority Updated' },
        }).then((_res) => _res.priorityScore);
    });
    const updateSolarPriority = (updateSolar) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.measure.postApiV1MeasureRefineSolar(convertToSolarRequest(updateSolar)), {
            error: {
                label: 'Error occurred updating Solar Priority',
            },
            success: {
                enabled: true,
                label: 'Solar Priority Updated',
            },
        }).then((_res) => _res.priorityScore);
    });
    const refreshScores = (facilityIdentity, orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield platformApi.measure.postApiV1MeasurePrioritize({
                facilityId: facilityIdentity,
                organizationId: orgIdentity,
            });
        }
        catch (error) {
            console.log({ error });
        }
    });
    const fetchUpdateTemplate = (data) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.measure.postApiV1MeasureExport(convertToMeasureExportRequest(data)), {
            error: { label: 'Error occurred exporting data.' },
            success: {
                enabled: false,
            },
        })
            .then((response) => {
            return convertBlobToFile(response, data.fileName, 'text/csv');
        })
            .catch(() => undefined);
    });
    const updateBulkFacilities = (request) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.measure.postApiV1MeasureImport(convertToMeasureImportRequest(request)), {
            error: {
                label: 'Error while updating facilities.',
                duration: null,
            },
            success: {
                label: 'Facilities Updated',
                enabled: true,
            },
        })
            .then((res) => {
            return res;
        })
            .catch(() => {
            return undefined;
        });
    });
    return {
        measure: {
            fetch: fetchPriority,
            fetchList: fetchSummary,
            refresh: refreshScores,
        },
        lighting: {
            update: updateLightingPriority,
        },
        ev: {
            update: updateEvPriority,
        },
        solar: {
            update: updateSolarPriority,
        },
        bulk: {
            export: fetchUpdateTemplate,
            update: updateBulkFacilities,
        },
    };
};
