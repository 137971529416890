var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, List, ListItem, Text } from '@chakra-ui/react';
import { ContainerWithHeader } from '@frontend/design-system/components/DataDisplay/ContainerWithHeader';
import { variants } from '@frontend/design-system/theme/theme';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { toast } from '@frontend/domain/ToastContainer';
import { useState } from 'react';
import { validateName } from '../../../utils/formValidations';
import { ProjectTypeInput } from './ProjectTypeInput';
export const ProjectTypesContainer = ({ canEdit, orgProjectTypes, upsertProjectType, deleteProjectType, orgPrioritizationAlgorithms, }) => {
    const [pageMode, setPageMode] = useState(SettingsPageMode.VIEW);
    const validateProjectType = (value, onInvalidName, onValidName) => {
        validateName(value, orgProjectTypes, onInvalidName, onValidName);
    };
    const hasAlgorithmChanged = (data) => {
        const foundUpdatedProjectType = orgProjectTypes.find((projectType) => projectType.identity === data.identity);
        if (foundUpdatedProjectType) {
            return foundUpdatedProjectType.algorithmId !== data.algorithmId;
        }
        return false;
    };
    const handleSave = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const isAlgorithmChanged = hasAlgorithmChanged(data);
        const response = yield upsertProjectType(data);
        if (response) {
            if (isAlgorithmChanged) {
                toast({
                    title: 'Prioritization Algorithm has been changed',
                    description: 'Go to myProjects to rescore any projects that are "Needs Action"',
                    status: 'warning',
                    duration: null,
                    isClosable: true,
                });
            }
            setPageMode(SettingsPageMode.VIEW);
        }
        return response;
    });
    const onDelete = (data) => {
        deleteProjectType(data);
    };
    const typeAlgorithmOptions = orgPrioritizationAlgorithms.map((algorithm, index) => {
        return {
            label: (index + 1).toString(),
            value: algorithm.identity,
        };
    });
    return (_jsx(ContainerWithHeader, { header: {
            label: 'Project Types',
            button: canEdit && (_jsx(Button, { onClick: () => {
                    setPageMode(SettingsPageMode.ADD_NEW);
                }, isDisabled: orgProjectTypes.length >= 20 ||
                    pageMode !== SettingsPageMode.VIEW, leftIcon: _jsx(AddIcon, {}), "aria-label": 'Add new Project Type', "data-testid": 'add-new-project-type', variant: variants.blueTransparentBtn, textTransform: 'none', pl: '10px', pr: '10px', children: "Add new" })),
        }, children: _jsxs(List, { spacing: 3, border: '1px solid #E6E7E9', borderRadius: '2px', p: '20px', height: 'calc(35vh)', overflow: 'auto', children: [_jsxs(ListItem, { display: 'flex', flexDirection: 'row', color: '#003B5D', fontSize: '14px', fontWeight: '600', w: '100%', children: [_jsx(Text, { w: '33.3%', marginRight: '5%', children: "Project Type" }), _jsx(Text, { w: '33.3%', children: "Prioritization Algorithm" })] }), pageMode === SettingsPageMode.ADD_NEW && (_jsx(ProjectTypeInput, { canEdit: canEdit, pageMode: pageMode, onDelete: onDelete, validateInput: validateProjectType, onSave: handleSave, setPageMode: setPageMode, options: typeAlgorithmOptions }, 'pt_new')), orgProjectTypes.length > 0 &&
                    orgProjectTypes.map((type) => {
                        return (_jsx(ProjectTypeInput, { canEdit: canEdit, pageMode: pageMode, originalData: type, onDelete: onDelete, validateInput: validateProjectType, onSave: handleSave, setPageMode: setPageMode, options: typeAlgorithmOptions }, `project_type_${type.name}`));
                    })] }) }));
};
