import { IAppNames } from '@frontend/domain/models/App';
export const getAppName = (app) => {
    switch (app) {
        case IAppNames.REPORTS:
            return 'Reports';
        case IAppNames.FCA:
            return 'Organize';
        case IAppNames.PLANNER:
            return 'Prioritize';
        case IAppNames.PIQ:
            return 'myOpportunities';
    }
};
