import { jsx as _jsx } from "react/jsx-runtime";
import { RepeatIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { toast } from '@frontend/domain/ToastContainer';
import { useReports } from '../../components/context/ReportsContext';
export const RefreshReportBtn = ({ onPowerBILoaded, }) => {
    const { refreshAssessment } = useReports();
    return (_jsx(Button, { onClick: () => {
            onPowerBILoaded(false);
            refreshAssessment()
                .then(() => {
                onPowerBILoaded(true);
                window.location.reload();
                toast({
                    title: 'Report Refreshed',
                    status: 'success',
                    isClosable: true,
                });
            })
                .catch((error) => {
                onPowerBILoaded(true);
                console.error('Refresh Error: ', error);
                toast({
                    title: 'Refresh Failed',
                    description: 'Please try again later.',
                    status: 'error',
                    isClosable: true,
                });
            });
        }, variant: variants.transparentReportBtn, leftIcon: _jsx(RepeatIcon, {}), "aria-label": 'refresh-report', children: "Refresh" }));
};
