import { toFriendlyFacilityRole, toOrgRoleEnum, toSystemRoleEnum, } from '../../conversion/roles';
import { IAppNames } from '../App';
import { OrganizationRoleEnum, SystemRole, } from '../Roles';
export const convertOrgContactToIUserContact = (user) => {
    return {
        identity: user.userIdentity,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.emailAddress,
    };
};
export const convertUserProfileResponseToIUser = (user) => {
    var _a, _b, _c;
    const { appAccess, organizationRoles, facilityExceptions } = splitAllAssignments(user.organizationAssignments || []);
    return {
        identity: user.userId,
        systemRole: toSystemRoleEnum(user.systemRole),
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress,
        phoneNumber: (_a = user.phoneNumber) !== null && _a !== void 0 ? _a : undefined,
        jobTitle: (_b = user.jobTitle) !== null && _b !== void 0 ? _b : undefined,
        isEulaAccepted: (_c = user.isEulaAccepted) !== null && _c !== void 0 ? _c : false,
        isActive: user.isActive,
        iconUrl: user.iconUrl,
        appAccess: appAccess,
        organizationRoles: organizationRoles,
        facilityExceptions: facilityExceptions,
        primaryContactOrganizations: user.primaryContactOrganizations.map(roleToPrimaryContact),
    };
};
const splitAllAssignments = (orgAssignments) => {
    const appAccessList = [];
    const organizationRoles = [];
    const facilityExceptions = [];
    orgAssignments.forEach((orgAssignment) => {
        organizationRoles.push(roleToOrg(orgAssignment));
        appAccessList.push(...orgAssignment.apps.map((app) => appToAccess(app, orgAssignment.organizationId)));
        facilityExceptions.push(...orgAssignment.facilityAssignments.map((facilityAssignment) => roleToFacility(facilityAssignment, orgAssignment.organizationId)));
    });
    return {
        appAccess: appAccessList,
        organizationRoles,
        facilityExceptions,
    };
};
function roleToOrg(role) {
    return {
        organizationIdentity: role.organizationId,
        role: toOrgRoleEnum(role.role),
        name: role.organizationName,
    };
}
function roleToFacility(role, organizationIdentity) {
    if (!role.facilityId) {
        console.error('User organization identity is missing', role);
        throw new Error('User organization identity is missing');
    }
    if (!role.role) {
        console.error('User organization role is missing', role);
        throw new Error('User organization role is missing');
    }
    return {
        organizationIdentity: organizationIdentity,
        facilityIdentity: role.facilityId,
        role: toFriendlyFacilityRole(role.role),
    };
}
function roleToPrimaryContact(response) {
    return {
        identity: response.organizationId,
        name: response.organizationName,
    };
}
function appToAccess(response, organizationIdentity) {
    const isValidEnum = Object.values(IAppNames).includes(response.appName);
    if (!isValidEnum)
        console.warn('Invalid App Name:', response.appName);
    return {
        organizationIdentity: organizationIdentity,
        name: response.appName,
        enabled: response.isActive,
    };
}
export const convertIAssignUserAccessToUserAssignmentRequest = (user) => {
    return {
        userId: user.identity,
        systemRole: user.systemRole.toString(),
        organizationAssignments: user.organizationAssignments.map(convertIUserOrganizationAssignmentToOrganizationAssignmentRequest),
    };
};
const convertIUserOrganizationAssignmentToOrganizationAssignmentRequest = (organizationAssignment) => {
    if (!organizationAssignment.organizationIdentity) {
        throw new Error('Organization assignment identity is missing.');
    }
    return {
        organizationId: organizationAssignment.organizationIdentity,
        role: organizationAssignment.role.toString(),
        apps: organizationAssignment.apps.map(convertIAppToUserAppAssignmentRequest),
        facilityAssignments: organizationAssignment.facilityAssignments.map(convertIFacilityExceptionToFacilityAssignmentRequest),
    };
};
const convertIAppToUserAppAssignmentRequest = (userApp) => {
    return {
        appName: userApp.app,
        isActive: userApp.enabled,
    };
};
const convertIFacilityExceptionToFacilityAssignmentRequest = (facilityException) => {
    if (!facilityException.facilityIdentity) {
        throw new Error('Facility exception identity is missing.');
    }
    return {
        facilityId: facilityException.facilityIdentity,
        role: facilityException.role,
    };
};
export const convertUserAssignFormToIAssignUserAccess = (userAssignForm) => {
    if (userAssignForm.identity === undefined) {
        throw new Error('Cannot update a user without an identity');
    }
    return {
        identity: userAssignForm.identity,
        systemRole: userAssignForm.systemRole || SystemRole.USER,
        organizationAssignments: userAssignForm.organizationAssignments.map(convertOrganizationAssignmentFormToIOrganizationAssignment),
    };
};
export const convertUserAssignFormToINewUser = (userAssignForm) => {
    return {
        firstName: userAssignForm.firstName,
        lastName: userAssignForm.lastName,
        email: userAssignForm.email,
        systemRole: userAssignForm.systemRole || SystemRole.USER,
        organizationAssignments: userAssignForm.organizationAssignments.map(convertOrganizationAssignmentFormToIOrganizationAssignment),
    };
};
export const convertINewUserToInvitationRequest = (newUser) => {
    return {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        emailAddress: newUser.email,
        systemRole: newUser.systemRole,
        organizationAssignments: newUser.organizationAssignments.map(convertIUserOrganizationAssignmentToOrganizationAssignmentRequest),
    };
};
export const convertOrganizationAssignmentFormToIOrganizationAssignment = (organizationAssignmentForm) => {
    return {
        organizationIdentity: organizationAssignmentForm.identity,
        role: organizationAssignmentForm.role,
        apps: organizationAssignmentForm.role ===
            OrganizationRoleEnum.CLIENT_CONCIERGE ||
            organizationAssignmentForm.role === OrganizationRoleEnum.LOCAL_ADMIN
            ? []
            : organizationAssignmentForm.appAccess.map(convertAppAccessFormToIApp),
        facilityAssignments: organizationAssignmentForm.role ===
            OrganizationRoleEnum.CLIENT_CONCIERGE ||
            organizationAssignmentForm.role === OrganizationRoleEnum.LOCAL_ADMIN
            ? []
            : organizationAssignmentForm.facilityExceptions.map((facilityException) => convertFacilityExceptionFormToIFacilityException(facilityException, organizationAssignmentForm.identity)),
    };
};
const convertAppAccessFormToIApp = (appAccessForm) => {
    return {
        app: appAccessForm.name,
        enabled: appAccessForm.isActive,
    };
};
const convertFacilityExceptionFormToIFacilityException = (facilityExceptionForm, organizationIdentity) => {
    if (!organizationIdentity) {
        throw new Error('Organization identity is missing.');
    }
    if (!facilityExceptionForm.identity) {
        throw new Error('Facility identity is required but was not provided.');
    }
    return {
        organizationIdentity: organizationIdentity,
        facilityIdentity: facilityExceptionForm.identity,
        role: facilityExceptionForm.role,
    };
};
export const convertIConfigureUserProfileToUserProfileRequest = (user) => {
    return {
        userId: user.identity,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        jobTitle: user.jobTitle,
        isEulaAccepted: user.isEulaAccepted,
        // TODO: add icon name when properly supported by the API
        // iconFileName: user.iconFileName !== '' ? user.iconFileName : null,
    };
};
export const convertUserProfileFormToIConfigureUserProfile = (profile, user) => {
    return {
        identity: user.identity,
        email: user.emailAddress,
        firstName: profile.firstName,
        lastName: profile.lastName,
        isEulaAccepted: profile.isEulaAccepted,
        phoneNumber: profile.phoneNumber,
        jobTitle: profile.jobTitle,
        // TODO: add icon name when properly supported by the API
        // iconFileName: user.iconUrl,
    };
};
export const convertIUserToUserProfileForm = (user) => {
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.emailAddress,
        jobTitle: user.jobTitle || '',
        phoneNumber: user.phoneNumber || undefined,
        isEulaAccepted: user.isEulaAccepted,
    };
};
// IUser will be undefined on create
export const convertIUserToUserAssignForm = (user, organizations, filterByOrg) => {
    const organizationAssignments = [];
    if (user) {
        if (filterByOrg) {
            const facilityExceptions = user.facilityExceptions.filter((exception) => exception.organizationIdentity === filterByOrg);
            const appAccess = user.appAccess.filter((access) => access.organizationIdentity === filterByOrg);
            const organizationRoles = user.organizationRoles.filter((roles) => roles.organizationIdentity == filterByOrg);
            organizationAssignments.push(...convertRolesToOrganizationAssignments(organizationRoles, facilityExceptions, appAccess, organizations));
        }
        else {
            organizationAssignments.push(...convertRolesToOrganizationAssignments(user.organizationRoles, user.facilityExceptions, user.appAccess, organizations));
        }
    }
    return {
        identity: user === null || user === void 0 ? void 0 : user.identity,
        firstName: (user === null || user === void 0 ? void 0 : user.firstName) || '',
        lastName: (user === null || user === void 0 ? void 0 : user.lastName) || '',
        email: (user === null || user === void 0 ? void 0 : user.emailAddress) || '',
        systemRole: (user === null || user === void 0 ? void 0 : user.systemRole) || SystemRole.EMPTY,
        phoneNumber: (user === null || user === void 0 ? void 0 : user.phoneNumber) || '',
        jobTitle: (user === null || user === void 0 ? void 0 : user.jobTitle) || '',
        organizationAssignments: organizationAssignments,
    };
};
const convertRolesToOrganizationAssignments = (organizationRoles, facilityExceptions, apps, organizations) => {
    const facilityExceptionMap = mapFacilityExceptionsByOrg(facilityExceptions);
    const appsMap = mapUserAppsByOrg(apps);
    return organizationRoles.map((organizationRole) => {
        const exceptionsForOrg = facilityExceptionMap.get(organizationRole.organizationIdentity) ||
            [];
        const appsForOrg = appsMap.get(organizationRole.organizationIdentity) || [];
        const foundOrg = organizations.find((org) => org.identity === organizationRole.organizationIdentity);
        return {
            identity: organizationRole.organizationIdentity,
            name: organizationRole.name,
            role: organizationRole.role,
            facilityExceptions: exceptionsForOrg.map(convertIFacilityExceptionToFacilityExceptionForm),
            appAccess: appsForOrg
                .filter((userApp) => {
                var _a;
                return (_a = foundOrg === null || foundOrg === void 0 ? void 0 : foundOrg.apps) === null || _a === void 0 ? void 0 : _a.some((orgApp) => orgApp.app === userApp.name && orgApp.enabled);
            })
                .map(convertIUserAppToAppAccessForm),
        };
    });
};
const convertIFacilityExceptionToFacilityExceptionForm = (facilityException) => {
    return {
        identity: facilityException.facilityIdentity,
        role: facilityException.role,
    };
};
const convertIUserAppToAppAccessForm = (userApp) => {
    return {
        name: userApp.name,
        isActive: userApp.enabled,
    };
};
const mapFacilityExceptionsByOrg = (facilityExceptions) => {
    const exceptionMap = new Map();
    facilityExceptions.forEach((facilityException) => {
        const exceptions = exceptionMap.get(facilityException.organizationIdentity);
        if (exceptions) {
            exceptions.push(facilityException);
            exceptionMap.set(facilityException.organizationIdentity, exceptions);
        }
        else {
            exceptionMap.set(facilityException.organizationIdentity, [
                facilityException,
            ]);
        }
    });
    return exceptionMap;
};
const mapUserAppsByOrg = (userApps) => {
    const userAppMap = new Map();
    userApps.forEach((userApp) => {
        const apps = userAppMap.get(userApp.organizationIdentity);
        if (apps) {
            apps.push(userApp);
            userAppMap.set(userApp.organizationIdentity, apps);
        }
        else {
            userAppMap.set(userApp.organizationIdentity, [userApp]);
        }
    });
    return userAppMap;
};
export const mapOganizationAssignmentFormToIOrganizationRole = (updatedOrgAssignment) => {
    var _a;
    return {
        organizationIdentity: (_a = updatedOrgAssignment.identity) !== null && _a !== void 0 ? _a : 0,
        role: updatedOrgAssignment.role,
        name: updatedOrgAssignment.name,
    };
};
