var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, Checkbox, FormControl, FormLabel, Heading, HStack, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { toast } from '@frontend/domain/ToastContainer';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useReports } from '../../../components/context/ReportsContext';
import { appColors } from '../../../config/constants';
import { AppContext } from '../../../objects/UserData';
import { validateGUID, } from '../../../utils/formValidations';
export const ReportModal = ({ orgIdentity, isOpen, onClose, }) => {
    const { reports, registerReport, currentReport, selectReport } = useReports();
    const [GUIDValidation, setGUIDValidation] = useState({
        workspaceKey: true,
        reportKey: true,
    });
    const [uniqueValidation, setUniqueValidation] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, setValue, } = useForm();
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        setValue(WORKSPACE_KEY, (_a = currentReport === null || currentReport === void 0 ? void 0 : currentReport.workspaceKey) !== null && _a !== void 0 ? _a : '');
        setValue(REPORT_KEY, (_b = currentReport === null || currentReport === void 0 ? void 0 : currentReport.reportKey) !== null && _b !== void 0 ? _b : '');
        setValue(REPORT_NAME, (_c = currentReport === null || currentReport === void 0 ? void 0 : currentReport.reportName) !== null && _c !== void 0 ? _c : '');
        setValue(APP_CONTEXT, (_d = currentReport === null || currentReport === void 0 ? void 0 : currentReport.appContext) !== null && _d !== void 0 ? _d : '');
        setValue(SHOW_CUSTOMER, (_e = currentReport === null || currentReport === void 0 ? void 0 : currentReport.isPublished) !== null && _e !== void 0 ? _e : false);
    }, [setValue, currentReport]);
    const validateUniqueness = (e) => {
        let reportFound;
        if (reports) {
            reportFound = reports.find((rep) => rep.reportKey === e.target.value.toUpperCase() &&
                rep.reportKey !== (currentReport === null || currentReport === void 0 ? void 0 : currentReport.reportKey));
        }
        if (reportFound) {
            setUniqueValidation(false);
        }
        else {
            setUniqueValidation(true);
        }
    };
    const validateReportType = () => {
        if (currentReport && reports) {
            const standartReports = reports.filter((report) => report.isCustom === false);
            const isReportStandard = standartReports.some((rep) => rep.isCustom === currentReport.isCustom);
            return isReportStandard;
        }
    };
    const workspaceKeyRegister = register(WORKSPACE_KEY, {
        required: true,
        onChange: (e) => validateGUID(e, WORKSPACE_KEY, setGUIDValidation),
    });
    const reportKeyRegister = register(REPORT_KEY, {
        required: true,
        onChange: (e) => {
            validateGUID(e, REPORT_KEY, setGUIDValidation);
            validateUniqueness(e);
        },
    });
    const reportNameRegister = register(REPORT_NAME, { required: true });
    const appContextRegister = register(APP_CONTEXT, { required: true });
    const isPublished = register(SHOW_CUSTOMER);
    const runReset = () => {
        reset({
            workspaceKey: '',
            reportKey: '',
            reportName: '',
            appContext: '',
            isPublished: false,
        });
    };
    const closeModal = () => {
        runReset();
        clearErrors();
        setGUIDValidation({
            workspaceKey: true,
            reportKey: true,
        });
        setUniqueValidation(true);
        selectReport(undefined);
        setIsSubmitting(false);
        onClose();
    };
    const renderError = (label) => {
        const fields = {
            'WorkSpace ID': 'workspaceKey',
            'Report ID': 'reportKey',
            'Report Name': 'reportName',
            'App Context': 'appContext',
        };
        const isGUIDValidation = label === 'WorkSpace ID' || label === 'Report ID';
        const isUniqueValidation = label === 'Report ID';
        const requiredField = fields[label];
        const validatedField = fields[label];
        if (requiredField && errors[requiredField]) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, "."] }));
        }
        else if (isGUIDValidation && !GUIDValidation[validatedField]) {
            return (_jsxs("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: ["Please enter ", label, " in a valid GUID format."] }));
        }
        else if (isUniqueValidation && !uniqueValidation) {
            return (_jsx("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: "This report already exists. Please enter a different Report ID." }));
        }
        return null;
    };
    const renderInput = (label, required, register, inputType) => {
        return (_jsxs(FormControl, { children: [(inputType === 'text' || inputType === 'select') && (_jsxs(HStack, { children: [_jsx(FormLabel, { mr: '3px', children: label }), required && (_jsx("p", { style: {
                                color: 'red',
                                marginLeft: '0',
                                marginBottom: '9px',
                            }, children: "*" }))] })), inputType === 'checkbox' ? (_jsx(Checkbox, Object.assign({ colorScheme: 'white', iconColor: appColors.SEC_ORANGE }, register, { children: _jsx(Text, { fontSize: '14px', children: "Show Customer" }) }))) : inputType === 'select' ? (_jsxs(Select, Object.assign({}, register, { placeholder: ' ', isDisabled: validateReportType(), children: [_jsx("option", { value: AppContext.organize, children: AppContext.organize }), _jsx("option", { value: AppContext.prioritize, children: AppContext.prioritize }), _jsx("option", { value: AppContext.optimize, children: AppContext.optimize })] }))) : (_jsx(Input, Object.assign({ type: inputType }, register, { isDisabled: validateReportType() }))), renderError(label)] }));
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (orgIdentity) {
                let reportData = Object.assign(Object.assign({}, data), { organizationId: orgIdentity, isCustom: true });
                if (currentReport) {
                    reportData = Object.assign(Object.assign({}, reportData), { reportId: currentReport.reportId, isCustom: currentReport.isCustom });
                }
                setIsSubmitting(true);
                yield registerReport(reportData);
                toast({
                    title: 'Saved',
                    status: 'success',
                    isClosable: true,
                });
            }
        }
        catch (e) {
            toast({
                title: 'Save failed',
                description: 'Please check that all fields are entered correctly.',
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            closeModal();
        }
    });
    return (_jsxs(Modal, { isOpen: isOpen, onClose: closeModal, trapFocus: false, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent, { minW: 506, borderRadius: 15, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 400, boxSizing: 'border-box', p: 4, overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(ModalHeader, { children: [_jsx(Heading, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, mb: '40px', children: currentReport ? 'Edit Report' : 'New Report' }), _jsx(Text, { color: appColors.DELETE_ERROR, fontSize: 10, fontWeight: 500, children: "(*) Required fields" })] }), _jsx(ModalBody, { children: _jsxs(Stack, { py: 2, spacing: 4, children: [renderInput('Show Customer', false, isPublished, 'checkbox'), renderInput('Report Name', true, reportNameRegister, 'text'), renderInput('App Context', true, appContextRegister, 'select'), renderInput('Report ID', true, reportKeyRegister, 'text'), renderInput('WorkSpace ID', true, workspaceKeyRegister, 'text')] }) }), _jsxs(ModalFooter, { pt: 5, display: 'flex', justifyContent: 'space-between', children: [_jsx(Button, { px: '20px', py: '8px', variant: variants.orangeTransparentModalBtn, fontSize: '14px', onClick: () => {
                                        closeModal();
                                    }, children: "Cancel" }), _jsx(Button, { type: 'submit', px: '20px', py: '8px', variant: variants.secDarkGrayBtn, fontSize: '14px', isDisabled: GUIDValidation.reportKey &&
                                        GUIDValidation.workspaceKey &&
                                        uniqueValidation &&
                                        !isSubmitting
                                        ? false
                                        : true, _hover: { variant: variants.secDarkGrayBtn }, children: currentReport ? 'Edit' : 'Add' })] })] }) })] }));
};
const WORKSPACE_KEY = 'workspaceKey';
const REPORT_KEY = 'reportKey';
const REPORT_NAME = 'reportName';
const APP_CONTEXT = 'appContext';
const SHOW_CUSTOMER = 'isPublished';
