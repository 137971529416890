import { faker } from '@faker-js/faker';
export class IScenarioPlanBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ totalAllocation: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), totalBudget: faker.number.int({ min: undefined, max: undefined }), totalEscalationImpact: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), hasRelatedProjects: faker.datatype.boolean(), budgetsByFundingType: [], budgetsByFacility: [], fundingTypeAllocations: [], projects: [], relationshipViolations: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IBudgetsByFacilityBuilder {
    constructor(overrides) {
        this.build = () => {
            const amount = faker.number.int();
            return Object.assign({ facilityId: faker.number.int(), facilityName: faker.lorem.slug(1), allocationPercentage: faker.number.float({ min: 0, max: 1 }), allocationAmount: amount, allocationByArea: faker.number.int({ max: amount }) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IFundingTypeAllocationBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ fundingTypeId: faker.number.int({
                    min: 1,
                    max: 50000,
                }), fundingTypeName: faker.lorem.slug(1), budget: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), planYear: faker.number.int({
                    min: 2022,
                    max: 2075,
                }), escalationImpact: undefined }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IBudgetByFundingTypeBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ fundingTypeId: faker.number.int({
                    min: 1,
                    max: 50000,
                }), fundingTypeName: faker.lorem.slug(1), budget: undefined, planYear: undefined }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class PlanCompareResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ scenarios: [
                    {
                        scenarioId: faker.number.int(),
                        scenarioName: faker.lorem.slug(1),
                        isLocked: faker.datatype.boolean(),
                        description: faker.lorem.slug(1),
                        photoFileUrl: faker.internet.url(),
                        projectCount: faker.number.int(),
                        totalAllocatedBudget: faker.number.int(),
                        averageYearlyBudget: faker.number.int(),
                        netAllocatedBudget: faker.number.int(),
                        totalEscalationImpact: faker.number.int(),
                        totalBudgetedForDueOrOverdueProjects: faker.number.int(),
                        totalSavings: faker.number.int(),
                        totalRebateImpact: faker.number.int(),
                        totalCO2eSavings: faker.number.int(),
                        totalEnergySavings: faker.number.int(),
                        totalWaterSavings: faker.number.int(),
                        totalThermsSavings: faker.number.int(),
                        taxImpact: null,
                        fundingTypeAllocations: [],
                        facilityAllocations: [],
                        highlightedProjects: [],
                        uniqueProjects: {},
                        monetarySavingsByYear: [],
                        priorities: [],
                        projectTypeBudget: [],
                    },
                ], overlappingProjects: [
                    {
                        projectId: faker.number.int(),
                        projectName: faker.lorem.slug(1),
                        facilityName: faker.lorem.slug(1),
                        projectPriority: null,
                    },
                ] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
