var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { isSystemAdmin, UserManagementContext, } from '@frontend/domain/contexts/Authorization/Roles';
import { convertIUserToUserAssignForm } from '@frontend/domain/models/User/UserConversions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AssignUserInputs } from '../UserManagement/AssignUserInputs';
export const AddOrEditAdminUserModal = ({ isOpen, onClose, userDirectory, addNewUser, assignUserAccess, userToEdit, organizations, currentUser, facilities, fetchFacilities, }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [removedOrgAssignments, setRemovedOrgAssignments] = useState([]);
    const [selectedOrgId, setSelectedOrgId] = useState(undefined);
    const createUpdateUserForm = () => {
        const user = convertIUserToUserAssignForm(userToEdit, organizations);
        user.phoneNumber = userToEdit === null || userToEdit === void 0 ? void 0 : userToEdit.phoneNumber;
        user.jobTitle = userToEdit === null || userToEdit === void 0 ? void 0 : userToEdit.jobTitle;
        return user;
    };
    useEffect(() => {
        if (selectedOrgId && fetchFacilities) {
            fetchFacilities(selectedOrgId);
        }
    }, [selectedOrgId]);
    const createNewUserForm = () => {
        const user = convertIUserToUserAssignForm(undefined, []);
        return user;
    };
    const userUseForm = useForm({
        values: userToEdit ? createUpdateUserForm() : createNewUserForm(),
        mode: 'onChange',
    });
    const { handleSubmit, reset, formState: { isValid, errors }, } = userUseForm;
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const formData = Object.assign(Object.assign({}, data), { organizationAssignments: isSystemAdmin(data.systemRole)
                ? []
                : data.organizationAssignments });
        setIsSubmitting(true);
        if (userToEdit) {
            assignUserAccess(formData, removedOrgAssignments)
                .then(() => {
                handleModalCloseActions();
            })
                .finally(() => setIsSubmitting(false));
        }
        else {
            addNewUser(formData)
                .then(() => {
                handleModalCloseActions();
            })
                .finally(() => setIsSubmitting(false));
        }
    });
    const validateUserEmail = (value) => {
        if (userToEdit) {
            return true;
        }
        return (!userDirectory.some((user) => user.emailAddress === value) ||
            'This user already exists within this organization. Please edit their existing profile.');
    };
    const handleModalCloseActions = () => {
        onClose();
        setRemovedOrgAssignments([]);
        reset();
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => {
            handleModalCloseActions();
        }, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { maxW: '80%', maxH: '80%', children: [_jsx(ModalHeader, { padding: 6, children: userToEdit ? 'Edit User' : 'New User' }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(AssignUserInputs, { form: userUseForm, validateUniqueEmail: validateUserEmail, editModal: userToEdit ? true : false, isEulaAccepted: userToEdit === null || userToEdit === void 0 ? void 0 : userToEdit.isEulaAccepted, currentUser: currentUser, userContext: UserManagementContext.ADMIN, organizations: organizations, userToEdit: userToEdit, removedOrgAssignments: removedOrgAssignments, setRemovedOrgAssignments: setRemovedOrgAssignments, facilities: facilities, setSelectedOrgId: setSelectedOrgId }), _jsxs(HStack, { paddingY: 6, w: '100%', justifyContent: 'space-between', children: [_jsx(Button, { variant: variants.transparentBlueBtn, ml: 3, onClick: () => {
                                                handleModalCloseActions();
                                            }, children: "Cancel" }), _jsx(Button, { type: 'submit', variant: variants.blueBtn, isDisabled: !isValid ||
                                                !!errors.organizationAssignments ||
                                                isSubmitting, children: "Save" })] })] }) })] })] }));
};
