var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, } from '@chakra-ui/react';
import { appColors } from '@frontend/design-system/theme/constants';
import { variants } from '@frontend/design-system/theme/theme';
import { toast } from '@frontend/domain/ToastContainer';
import { useForm } from 'react-hook-form';
import { addTagToOrg } from '../../../services/api/orgDetailsAPI';
import { tagColors } from './MyPhotosTab';
export const AddTagModal = ({ orgTags, orgIdentity, isOpen, onClose, getTags, }) => {
    const { register, handleSubmit, reset, watch } = useForm();
    const nameRegister = register(NAME_KEY, {
        required: true,
    });
    const renderInput = (register) => {
        return (_jsxs(FormControl, { children: [_jsx(Input, Object.assign({ "data-testid": 'create-tag-name', type: 'string', placeholder: 'Tag' }, register)), renderError()] }));
    };
    const renderError = () => {
        const tagFound = orgTags.find((tag) => tag.name === watch('name'));
        if (tagFound) {
            return (_jsx("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: "Tag name already exists." }));
        }
        if (watch('name') && watch('name').length > 16) {
            return (_jsx("p", { style: {
                    color: 'red',
                    paddingTop: 1,
                    paddingLeft: 1,
                    fontSize: 'sm',
                }, children: "Tag name is too long." }));
        }
    };
    const assingColor = () => {
        const orgColors = orgTags.map((orgTag) => orgTag.color);
        for (const color of tagColors) {
            if (!orgColors.includes(color)) {
                return color;
            }
        }
        return null; // If no color is found
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const color = assingColor();
        try {
            if (orgIdentity) {
                yield addTagToOrg(orgIdentity, {
                    name: data.name,
                    color: color,
                });
                toast({
                    title: 'Saved',
                    status: 'success',
                    isClosable: true,
                });
                getTags();
                onClose();
                runReset();
            }
        }
        catch (_a) {
            toast({
                title: 'Save failed',
                description: 'Please check that all fields are entered correctly.',
                status: 'error',
                isClosable: true,
            });
        }
    });
    const hasError = () => {
        const tagFound = orgTags.find((tag) => tag.name === watch('name'));
        if (tagFound) {
            return true;
        }
        else if (watch('name') && watch('name').length > 16) {
            return true;
        }
        else {
            return false;
        }
    };
    const runReset = () => {
        reset({
            name: '',
        });
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => {
            onClose();
            runReset();
        }, children: [_jsx(ModalOverlay, {}), _jsx(ModalContent, { borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(ModalHeader, { color: appColors.TEXT_COLOR, fontSize: 36, fontWeight: 400, children: "New Tag" }), _jsx(ModalCloseButton, { mt: 7, mr: 7, size: 'lg' }), _jsx(ModalBody, { minH: '100px', children: _jsx(Stack, { py: 2, spacing: 5, children: renderInput(nameRegister) }) }), _jsx(ModalFooter, { pt: 5, children: _jsx(Button, { type: 'submit', px: '20px', py: '8px', variant: variants.addNewBtn, fontSize: '14px', _hover: { variant: variants.secDarkGrayBtn }, isDisabled: hasError(), children: "Save" }) })] }) })] }));
};
const NAME_KEY = 'name';
