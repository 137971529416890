export const convertToIFacilityMeasureSummary = (summaryResponse) => {
    var _a, _b, _c, _d, _e, _f;
    return {
        facilityId: summaryResponse.facilityId,
        facilityName: summaryResponse.facilityName,
        primaryFacilityType: summaryResponse.primaryFacilityType,
        secondaryFacilityType: (_a = summaryResponse.secondaryFacilityType) !== null && _a !== void 0 ? _a : undefined,
        tertiaryFacilityType: (_b = summaryResponse.tertiaryFacilityType) !== null && _b !== void 0 ? _b : undefined,
        sizeUnits: summaryResponse.sizeUnits,
        totalSize: summaryResponse.totalSize,
        stateOrProvince: summaryResponse.stateOrProvince,
        buildYear: summaryResponse.yearBuilt,
        customerInternalId: (_c = summaryResponse.customerInternalId) !== null && _c !== void 0 ? _c : undefined,
        propertyManager: (_d = summaryResponse.propertyManager) !== null && _d !== void 0 ? _d : undefined,
        hasPropertySurvey: summaryResponse.hasPropertySurvey ? 'Yes' : 'No',
        hasCompletedAudit: summaryResponse.hasCompletedAudit ? 'Yes' : 'No',
        facilityGrouper1: (_e = summaryResponse.facilityGrouper1) !== null && _e !== void 0 ? _e : undefined,
        facilityGrouper2: (_f = summaryResponse.facilityGrouper2) !== null && _f !== void 0 ? _f : undefined,
        evPriority: summaryResponse.ev
            ? convertToPrioritySummary(summaryResponse.ev)
            : undefined,
        lightingPriority: summaryResponse.lighting
            ? convertToPrioritySummary(summaryResponse.lighting)
            : undefined,
        solarPriority: summaryResponse.solar
            ? convertToPrioritySummary(summaryResponse.solar)
            : undefined,
    };
};
const convertToPrioritySummary = (prioritySummary) => {
    return {
        confidenceScore: prioritySummary.confidenceScore,
        priorityScore: prioritySummary.priorityScore,
    };
};
export const convertToIFacilityPriority = (priorityResponse) => {
    return {
        facilityId: priorityResponse.facilityId,
        evPriority: priorityResponse.evPriority
            ? convertEVResponseToIEVPriority(priorityResponse.evPriority)
            : undefined,
        lightingPriority: priorityResponse.lightingPriority
            ? convertLightingResponseToILightingPriority(priorityResponse.lightingPriority)
            : undefined,
        solarPriority: priorityResponse.solarPriority
            ? convertSolarResponseToISolarPriority(priorityResponse.solarPriority)
            : undefined,
    };
};
const convertEVResponseToIEVPriority = (ev) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    return {
        evPortCount: ev.evPortCount,
        isEVPortCountProxy: ev.isEVPortCountProxy,
        buildComplexity: (_a = ev.buildComplexity) !== null && _a !== void 0 ? _a : undefined,
        isBuildComplexityProxy: (_b = ev.isBuildComplexityProxy) !== null && _b !== void 0 ? _b : undefined,
        marketDemand: ev.marketDemand,
        permitDifficulty: (_c = ev.permitDifficulty) !== null && _c !== void 0 ? _c : undefined,
        isPermitDifficultyProxy: (_d = ev.isPermitDifficultyProxy) !== null && _d !== void 0 ? _d : undefined,
        customerPriority: ev.customerPriority,
        rebatePotential: ev.rebatePotential,
        isRebatePotentialProxy: ev.isRebatePotentialProxy,
        isRebateImportant: ev.isRebateImportant,
        priorityScore: (_e = ev.priorityScore) !== null && _e !== void 0 ? _e : undefined,
        confidenceScore: (_f = ev.confidenceScore) !== null && _f !== void 0 ? _f : undefined,
        laborCost: (_g = ev.laborCost) !== null && _g !== void 0 ? _g : undefined,
        carManufacturerRequirement: (_h = ev.carManufacturerRequirement) !== null && _h !== void 0 ? _h : undefined,
        isCarManufacturerRequirementProxy: (_j = ev.isCarManufacturerRequirementProxy) !== null && _j !== void 0 ? _j : undefined,
        propertySize: (_k = ev.propertySize) !== null && _k !== void 0 ? _k : undefined,
        isPropertySizeProxy: (_l = ev.isPropertySizeProxy) !== null && _l !== void 0 ? _l : undefined,
        electricalCapacity: ev.electricalCapacity,
        isElectricalCapacityProxy: ev.isElectricalCapacityProxy,
        mandates: (_m = ev.mandates) !== null && _m !== void 0 ? _m : undefined,
        isMandatesProxy: (_o = ev.isMandatesProxy) !== null && _o !== void 0 ? _o : undefined,
        fleetMandates: (_p = ev.fleetMandates) !== null && _p !== void 0 ? _p : undefined,
        isFleetMandatesProxy: (_q = ev.isFleetMandatesProxy) !== null && _q !== void 0 ? _q : undefined,
        tenantFleetRequirement: (_r = ev.tenantFleetRequirement) !== null && _r !== void 0 ? _r : undefined,
        isTenantFleetRequirementProxy: (_s = ev.isTenantFleetRequirementProxy) !== null && _s !== void 0 ? _s : undefined,
    };
};
const convertLightingResponseToILightingPriority = (lighting) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return {
        energyMandates: lighting.energyMandates,
        electricityRate: lighting.electricityRate,
        isElectricityRateProxy: lighting.isElectricityRateProxy,
        retrofitYear: (_a = lighting.retrofitYear) !== null && _a !== void 0 ? _a : undefined,
        isRetrofitYearProxy: (_b = lighting.isRetrofitYearProxy) !== null && _b !== void 0 ? _b : undefined,
        retrofitPercentage: lighting.retrofitPercentage,
        isRetrofitPercentageProxy: lighting.isRetrofitPercentageProxy,
        projectSize: lighting.projectSize,
        isProjectSizeProxy: lighting.isProjectSizeProxy,
        laborCost: lighting.laborCost,
        customerPriority: lighting.customerPriority,
        rebatePotential: lighting.rebatePotential,
        isRebatePotentialProxy: lighting.isRebatePotentialProxy,
        exteriorPropertySize: (_c = lighting.exteriorPropertySize) !== null && _c !== void 0 ? _c : undefined,
        isExteriorPropertySizeProxy: (_d = lighting.isExteriorPropertySizeProxy) !== null && _d !== void 0 ? _d : undefined,
        percentLandlordControlled: (_e = lighting.percentLandlordControlled) !== null && _e !== void 0 ? _e : undefined,
        isPercentLandlordControlledProxy: (_f = lighting.isPercentLandlordControlledProxy) !== null && _f !== void 0 ? _f : undefined,
        isRebateImportant: lighting.isRebateImportant,
        priorityScore: (_g = lighting.priorityScore) !== null && _g !== void 0 ? _g : undefined,
        confidenceScore: (_h = lighting.confidenceScore) !== null && _h !== void 0 ? _h : undefined,
    };
};
const convertSolarResponseToISolarPriority = (solar) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return {
        solarIrradiance: (_a = solar.solarIrradiance) !== null && _a !== void 0 ? _a : undefined,
        electricityRate: solar.electricityRate,
        isElectricityRateProxy: solar.isElectricityRateProxy,
        statePolicies: solar.statePolicies,
        isStatePoliciesProxy: solar.isStatePoliciesProxy,
        localPolicies: (_b = solar.localPolicies) !== null && _b !== void 0 ? _b : undefined,
        isLocalPoliciesProxy: (_c = solar.isLocalPoliciesProxy) !== null && _c !== void 0 ? _c : undefined,
        rebatePotential: solar.rebatePotential,
        isRebatePotentialProxy: solar.isRebatePotentialProxy,
        laborCost: solar.laborCost,
        percentLandlordControlled: (_d = solar.percentLandlordControlled) !== null && _d !== void 0 ? _d : undefined,
        isPercentLandlordControlledProxy: (_e = solar.isPercentLandlordControlledProxy) !== null && _e !== void 0 ? _e : undefined,
        siteConfiguration: (_f = solar.siteConfiguration) !== null && _f !== void 0 ? _f : undefined,
        isSiteConfigurationProxy: (_g = solar.isSiteConfigurationProxy) !== null && _g !== void 0 ? _g : undefined,
        propertyDemand: solar.propertyDemand,
        isPropertyDemandProxy: solar.isPropertyDemandProxy,
        roofSize: solar.roofSize,
        isRoofSizeProxy: solar.isRoofSizeProxy,
        roofInstallationYear: solar.roofInstallationYear,
        isRoofInstallationYearProxy: solar.isRoofInstallationYearProxy,
        businessRequirements: (_h = solar.businessRequirements) !== null && _h !== void 0 ? _h : undefined,
        isBusinessRequirementsProxy: (_j = solar.isBusinessRequirementsProxy) !== null && _j !== void 0 ? _j : undefined,
        customerPriority: solar.customerPriority,
        isRebateImportant: solar.isRebateImportant,
        priorityScore: (_k = solar.priorityScore) !== null && _k !== void 0 ? _k : undefined,
        confidenceScore: (_l = solar.confidenceScore) !== null && _l !== void 0 ? _l : undefined,
    };
};
export const convertToEVRequest = (ev) => {
    return {
        facilityId: ev.facilityId,
        customerPriority: ev.customerPriority,
        rebatePotential: ev.rebatePotential,
        isRebateImportant: ev.isRebateImportant,
        evPortCount: ev.evPortCount,
        mandates: ev.mandates,
        fleetMandates: ev.fleetMandates,
        permitDifficulty: ev.permitDifficulty,
        carManufacturerRequirement: ev.carManufacturerRequirement,
        buildComplexity: ev.buildComplexity,
        propertySize: ev.propertySize,
        electricalCapacity: ev.electricalCapacity,
        tenantFleetRequirement: ev.tenantFleetRequirement,
    };
};
export const convertToLightingRequest = (lighting) => {
    return {
        facilityId: lighting.facilityId,
        customerPriority: lighting.customerPriority,
        rebatePotential: lighting.rebatePotential,
        isRebateImportant: lighting.isRebateImportant,
        electricityRate: lighting.electricityRate,
        retrofitYear: lighting.retrofitYear,
        retrofitPercentage: lighting.retrofitPercentage,
        percentLandlordControlled: lighting.percentLandlordControlled,
        projectSize: lighting.projectSize,
        exteriorPropertySize: lighting.exteriorPropertySize,
    };
};
export const convertToSolarRequest = (solar) => {
    return {
        facilityId: solar.facilityId,
        customerPriority: solar.customerPriority,
        rebatePotential: solar.rebatePotential,
        isRebateImportant: solar.isRebateImportant,
        electricityRate: solar.electricityRate,
        statePolicies: solar.statePolicies,
        localPolicies: solar.localPolicies,
        percentLandlordControlled: solar.percentLandlordControlled,
        siteConfiguration: solar.siteConfiguration,
        propertyDemand: solar.propertyDemand,
        roofSize: solar.roofSize,
        roofInstallationYear: solar.roofInstallationYear,
        businessRequirements: solar.businessRequirements,
    };
};
export const convertToMeasureExportRequest = (data) => {
    return {
        organizationId: data.orgId,
        facilityType: data.facilityType,
    };
};
export const convertToMeasureImportRequest = (data) => {
    return {
        OrganizationId: data.orgId,
        FacilityType: data.facilityType,
        File: data.file,
    };
};
